import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

 body {
    font-family: 'Arial', sans-serif;
    color: #fff;
    background-color: #D7CFCF; /* Define a cor de fundo do body */
    overflow-y: overlay; /* Oculta o scroll visual mas mantém a funcionalidade */
  }

  body::-webkit-scrollbar {
    display: none; /* Oculta o scrollbar no Chrome, Safari e Edge */
  }
`;

export default GlobalStyles;
