import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <HeaderContainer>
      <LeftSection>
        <LogoLink to="/">
          <LogoImage
            src={require("../assets/HALLU-logo-escrita.png")}
            alt="Hallu Logo"
          />
        </LogoLink>
      </LeftSection>
      <CenterSection>
        <LogoLink to="/">
          <Logo>
            <img src={require("../assets/LOGO.png")} alt="Logo Hallu" />
          </Logo>
        </LogoLink>
      </CenterSection>
      <RightSection>
        <Actions>
          <MenuContainer>
            <MenuButton onClick={toggleMenu}>
              {menuOpen ? <CloseIcon>×</CloseIcon> : <MenuIcon />}
            </MenuButton>
            <HorizontalMenu menuOpen={menuOpen}>
              <NavItem>
                <NavLink to="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/products">Products</NavLink>
              </NavItem>
            </HorizontalMenu>
          </MenuContainer>
          <ContactButton
            onClick={() =>
              window.open(
                "https://www.instagram.com/hallulabzoficial/",
                "_blank"
              )
            }
          >
            CONTACT US
          </ContactButton>
        </Actions>
      </RightSection>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  background: black;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  @media (max-width: 768px) {
    padding: 0px 20px;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const LogoLink = styled(Link)`
  text-decoration: none;
`;

const LogoImage = styled.img`
  width: 120px;
  height: auto;

  @media (max-width: 768px) {
    width: 100px;
  }
`;

const CenterSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.div`
  img {
    width: 80px;
    height: auto;

    @media (max-width: 768px) {
      width: 60px;
    }
  }
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const MenuContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const MenuButton = styled.button`
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const MenuIcon = styled.div`
  width: 20px;
  height: 2px;
  background: #000;
  position: relative;

  &::before,
  &::after {
    content: "";
    width: 20px;
    height: 2px;
    background: #000;
    position: absolute;
    left: 0;
  }

  &::before {
    top: -6px;
  }

  &::after {
    top: 6px;
  }
`;

const CloseIcon = styled.div`
  font-size: 24px;
  color: #000;
`;

const ContactButton = styled.button`
  background: #00ff00;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  @media (max-width: 768px) {
    display: none; /* Esconde o botão em dispositivos móveis */
  }
`;

const HorizontalMenu = styled.div`
  position: absolute;
  top: 60%; /* Alterado para mover o menu mais para baixo */
  right: 100%;
  transform: translateY(-50%);
  display: flex;
  gap: 20px;
  background: black;
  padding: 15px;
  border-radius: 8px;
  z-index: 10;

  opacity: ${({ menuOpen }) => (menuOpen ? "1" : "0")};
  visibility: ${({ menuOpen }) => (menuOpen ? "visible" : "hidden")};
  transform: ${({ menuOpen }) =>
    menuOpen ? "translateY(-50%)" : "translateY(-20px)"};
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;

  @media (max-width: 768px) {
    position: fixed;
    top: 180px;
    right: 10px;
    flex-direction: column;
    width: 150px;
  }
`;

const NavItem = styled.div``;

const NavLink = styled(Link)`
  color: #00ff00;
  text-decoration: none;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background 0.3s ease, color 0.3s ease;

  &:hover {
    background: #00ff00;
    color: #000;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 8px 16px;
  }
`;

export default Header;
