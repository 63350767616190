import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const BackgroundAnimation = () => {
  return (
    <AnimationContainer>
      {[...Array(20)].map((_, i) => (
        <motion.div
          key={i}
          className="circle"
          initial={{ scale: 0, opacity: 0 }}
          animate={{
            scale: [0, 1, 0],
            opacity: [0, 1, 0],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            delay: i * 0.2,
          }}
        />
      ))}
    </AnimationContainer>
  );
};

const AnimationContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;

  .circle {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    top: calc(50% - 25px);
    left: calc(50% - 25px);

    @media (max-width: 768px) {
      width: 30px; 
      height: 30px;
      top: calc(50% - 15px);
      left: calc(50% - 15px);
    }

    @media (max-width: 480px) {
      width: 20px; 
      height: 20px;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
    }
  }
`;

export default BackgroundAnimation;
