import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const Loader = ({ onFinish }) => {
  const [progress, setProgress] = useState(5);
  const [fadeOut, setFadeOut] = useState(false); 

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + 5 : prev));
    }, 100); 

    if (progress >= 100) {
      setTimeout(() => {
        setFadeOut(true); 
        setTimeout(() => {
          onFinish();
        }, 1000); 
      }, 500);
    }

    return () => clearInterval(interval);
  }, [progress, onFinish]);

  return (
    <LoaderContainer fadeOut={fadeOut}>
      <Logo src={require("../assets/LOGO-LOADER.png")} alt="Logo" />
      <LoadingText>{progress} / 100</LoadingText>
    </LoaderContainer>
  );
};

const fadeOutAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
`;

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: ${({ fadeOut }) => (fadeOut ? 0 : 1)};
  animation: ${({ fadeOut }) => (fadeOut ? fadeOutAnimation : "none")} 1s ease;
  pointer-events: ${({ fadeOut }) => (fadeOut ? "none" : "auto")};
`;

const Logo = styled.img`
  width: 600px;
  height: auto;
  margin-bottom: 20px;
`;

const LoadingText = styled.p`
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin-top: 10px;
`;

export default Loader;
