import React, { useEffect, useState } from "react";
import styled from "styled-components";

const HeroSection = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const { innerWidth, innerHeight } = window;
    const x = (clientX / innerWidth - 0.5) * 6;
    const y = (clientY / innerHeight - 0.5) * 6;
    setMousePosition({ x, y });
  };

  useEffect(() => {
    const video = document.querySelector("video");
    if (video) {
      video.play().catch((error) => {
        console.error("Erro ao reproduzir o vídeo:", error);
      });
    }
  }, []);

  return (
    <HeroContainer onMouseMove={handleMouseMove}>
      <BackgroundVideo
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
        src={require("../assets/VIDEO PAGINA INICIAL (2).mp4")}
        style={{
          transform: `perspective(100px) rotateX(${mousePosition.y}deg) rotateY(${mousePosition.x}deg)`,
        }}
      />
    </HeroContainer>
  );
};

const HeroContainer = styled.div`
  display: flex; /* Centraliza o conteúdo usando flexbox */
  justify-content: center; /* Alinha horizontalmente ao centro */
  align-items: center; /* Alinha verticalmente ao centro */
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 70vh;
  }
`;

const BackgroundVideo = styled.video`
  width: 70%; /* Ajusta o tamanho do vídeo */
  height: auto; /* Mantém a proporção */
  object-fit: contain; /* Garante que o vídeo se ajuste sem cortar */

  @media (max-width: 768px) {
    width: 90%; /* Reduz o tamanho do vídeo em telas menores */
  }

  @media (max-width: 480px) {
    width: 100%; /* O vídeo ocupa a largura total em dispositivos móveis */
  }
`;

export default HeroSection;
