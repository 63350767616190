import React from "react";
import styled from "styled-components";
import FundoAbout from "../assets/fundo-about.jpg";
import Logo from "../assets/HALLU-logo-escrita.png";
import Direita from "../assets/DIREITA.png";

const AboutUs = () => {
  return (
    <AboutContainer>
      <RightSection>
        <ProductImage src={Direita} alt="Products Image" />
      </RightSection>
      <LeftSection>
        <LogoImage src={Logo} alt="Hallu Logo" />
        <Text>
          At Hallu, our mission is to redefine the vape experience, blending
          cutting-edge design with the essence of THC. With years of expertise,
          we’re known for our stylish, high-quality pods that deliver more than
          just a product—they create a sensory journey. We aim to take our
          customers beyond the ordinary, exploring new sensations and emotions
          through cannabis in a refreshing way. Hallu isn’t just a vape brand;
          it’s an invitation to discover a new dimension in cannabis
          consumption. Join us and elevate your experience.
        </Text>
      </LeftSection>
    </AboutContainer>
  );
};

const AboutContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 100px;
  color: #fff;

  background-image: linear-gradient(
      rgba(0, 0, 0, 0.7), /* Sobreposição escura */
      rgba(0, 0, 0, 0.7)
    ),
    url(${FundoAbout}); /* Fundo com a imagem */
  background-size: cover;
  background-position: center;

  @media (max-width: 1024px) {
    flex-direction: column; /* Empilha os elementos em tablets e mobile */
    padding: 50px;
    height: auto; /* Ajusta a altura automaticamente */
  }
`;

const LeftSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }

  @media (max-width: 480px) {
    order: 2; /* Altera a ordem do elemento em dispositivos móveis */
  }
`;

const LogoImage = styled.img`
  width: 250px;

  @media (max-width: 768px) {
    width: 200px; /* Reduz o tamanho do logo para dispositivos menores */
  }
`;

const Text = styled.p`
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 20px;
  line-height: 1.8;
  text-align: justify;

  @media (max-width: 1024px) {
    font-size: 18px; /* Ajusta o tamanho da fonte para tablets */
  }

  @media (max-width: 768px) {
    font-size: 16px; /* Ajusta o tamanho da fonte para dispositivos móveis */
    text-align: center; /* Centraliza o texto em dispositivos menores */
  }
`;

const RightSection = styled.div`
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%; /* Ocupa a largura total em dispositivos menores */
  }

  @media (max-width: 480px) {
    order: 1; /* Altera a ordem do elemento em dispositivos móveis */
  }
`;

const ProductImage = styled.img`
  width: 180%;
  border-radius: 20px;

  @media (max-width: 480px) {
    width: 200%; /* Ajusta a largura da imagem para dispositivos móveis */
  }
`;

export default AboutUs;
