import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa"; // Ícones de som
import GlobalStyles from "./styles/globalStyles";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Carousel from "./components/Carousel";
import InteractiveBanner from "./components/InteractiveBanner";
import Footer from "./components/Footer";
import Products from "./components/products";
import Loader from "./components/Loader";
import BackgroundAnimation from "./components/BackgroundAnimation";
import GooniesInspiredSection from "./components/GooniesInspiredSection";
import AboutUs from "./components/aboutUs";
import backgroundMusic from "./assets/groove-delight.mp3";

const App = () => {
  const [loading, setLoading] = useState(true); // Controle do Loader
  const [isAgeVerified, setIsAgeVerified] = useState(false); // Controle da idade verificada
  const [isMuted, setIsMuted] = useState(false); // Controle do som
  const audioRef = useRef(null);

  const handleLoaderFinish = () => setLoading(false);

  const handleAgeVerification = (isVerified) => {
    if (isVerified) {
      setIsAgeVerified(true);
      if (audioRef.current) {
        audioRef.current.play(); // Toca a música após a verificação de idade
      }
    } else {
      window.location.href = "https://www.google.com"; // Redireciona para outra página
    }
  };

  // Ajusta o volume e mute quando o estado muda
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = isMuted ? 0 : 0.05; // Volume ajustado para 10%
      audioRef.current.muted = isMuted;
    }
  }, [isMuted]);

  return (
    <Router>
      <GlobalStyles />
      {loading && <Loader onFinish={handleLoaderFinish} />}
      {!loading && !isAgeVerified && (
        <AgeVerificationModal onVerify={handleAgeVerification} />
      )}
      <FixedHeader>
        <Header />
      </FixedHeader>
      <SoundControl onClick={() => setIsMuted((prev) => !prev)}>
        {isMuted ? <FaVolumeMute size={24} /> : <FaVolumeUp size={24} />}
      </SoundControl>
      <audio ref={audioRef} loop>
        <source src={backgroundMusic} type="audio/mpeg" />
      </audio>
      <MainContent $show={!loading && isAgeVerified}>
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <HeroSection />
                <Carousel />
                <BackgroundAnimation />
                <InteractiveBanner />
                <AboutUs />
                <GooniesInspiredSection />
                <Footer />
              </div>
            }
          />
          <Route path="/products" element={<Products />} />
        </Routes>
      </MainContent>
    </Router>
  );
};

const FixedHeader = styled.div`
  position: fixed;
  width: 100%;
  z-index: 100;
`;

const SoundControl = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #ffffff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s, box-shadow 0.2s;
  z-index: 1000;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.25);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
`;

const MainContent = styled.div`
  margin-top: 80px;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  transform: ${({ $show }) => ($show ? "translateY(0)" : "translateY(10px)")};
  transition: opacity 0.5s ease, transform 0.5s ease;
`;

const AgeVerificationModal = ({ onVerify }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <ModalMessage>Are you 18 years or older?</ModalMessage>
        <ModalButtons>
          <ModalButton onClick={() => onVerify(true)} isConfirm>
            Sim
          </ModalButton>
          <ModalButton onClick={() => onVerify(false)}>Não</ModalButton>
        </ModalButtons>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  width: 90%;
  max-width: 400px;
`;

const ModalMessage = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  color: #000;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  flex: 1;

  background: ${({ isConfirm }) => (isConfirm ? "#00ff00" : "#ff0000")};
  color: ${({ isConfirm }) => (isConfirm ? "#000" : "#fff")};

  &:hover {
    background: ${({ isConfirm }) => (isConfirm ? "#009900" : "#cc0000")};
  }
`;

export default App;
