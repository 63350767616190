import React from "react";
import styled from "styled-components";

// Dados dos produtos
const products = [
  {
    id: 1,
    name: "Tropical cookies",
    // price: "$10.00",
    description: "This pod offers a tropical and sweet flavor, reminiscent of exotic cookies. Provides a relaxing and happy sensation, ideal for moments of relaxation.",
    image: require("../assets/pods-carousel/pod1.png"),
  },
  {
    id: 2,
    name: "Ice cream cake",
    // price: "$12.00",
    description: "With a creamy and smooth essence, this pod brings the comfort of delicious ice cream, promoting deep relaxation and a feeling of peace.",
    image: require("../assets/pods-carousel/pod2.png"),
  },
  {
    id: 3,
    name: "White Runtz",
    // price: "$15.00",
    description: "Fruity and sweet, this option offers a balanced experience between relaxation and light euphoria, perfect for those looking for a good dose of well-being.",
    image: require("../assets/pods-carousel/pod3.png"),
  },
  {
    id: 4,
    name: " Watermelon Skitlez",
    // price: "$8.00",
    description: "A refreshing and juicy touch of watermelon, which awakens the mood and brings a vibrant and happy feeling, like a summer afternoon.",
    image: require("../assets/pods-carousel/pod4.png"),
  },
  {
    id: 5,
    name: " Strawberry Cheese Cake",
    // price: "$20.00",
    description: "With the sweet and creamy taste of strawberry cheesecake, it provides a feeling of comfort and contentment, ideal for relaxing and enjoying the moment..",
    image: require("../assets/pods-carousel/pod5.png"),
  },
  {
    id: 6,
    name: "Super Lemon Haze",
    // price: "$18.00",
    description: "Energizing and citrusy, this pod brings an invigorating and stimulating sensation, perfect for those who want focus and disposition..",
    image: require("../assets/pods-carousel/pod6.png"),
  },
  {
    id: 7,
    name: "King Louis XVII",
    // price: "$25.00",
    description: "Inspired by real aroma, it provides a full-bodied and robust experience, with a feeling of deep and majestic relaxation, ideal for moments of self-care.",
    image: require("../assets/pods-carousel/pod7.png"),
  },
];

const Products = () => {
  return (
    <ProductsContainer>
      <Title>Nossos Produtos</Title>
      <ProductGrid>
        {products.map((product) => (
          <ProductCard key={product.id}>
            <ProductImage src={product.image} alt={product.name} />
            <ProductInfo>
              <ProductName>{product.name}</ProductName>
              <ProductPrice>{product.price}</ProductPrice>
              <ProductDescription>{product.description}</ProductDescription>
            </ProductInfo>
          </ProductCard>
        ))}
      </ProductGrid>
    </ProductsContainer>
  );
};

// Estilos
const ProductsContainer = styled.div`
  padding: 80px;
  background-color: #f8f8f8;
  min-height: 100vh;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  font-size: 36px;
  color: #333;
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px; 

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr); 
    gap: 10px; 
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); 
    gap: 8px; 
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; 
    gap: 5px; 
  }
`;



const ProductCard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.2s;
  max-width: 250px;
  margin: 0 auto;

  &:hover {
    transform: scale(1.05);
  }
`;

const ProductImage = styled.img`
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-bottom: 10px;
  border-radius: 5px;
`;

const ProductInfo = styled.div`
  text-align: center;
`;

const ProductName = styled.h2`
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
`;

const ProductPrice = styled.p`
  font-size: 16px;
  color: #00aaff;
  margin-bottom: 10px;
`;

const ProductDescription = styled.p`
  font-size: 14px;
  color: #555;
`;

export default Products;
