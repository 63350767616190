import React, { useEffect, useState } from "react";
import styled from "styled-components";

const InteractiveBanner = () => {
  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    // Garante que o vídeo seja reproduzido automaticamente no carregamento
    const video = document.querySelector("video");
    if (video) {
      video.play().catch((error) => {
        console.error("Erro ao reproduzir o vídeo:", error);
      });
    }
  }, []);

  return (
    <BannerContainer
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      hovering={hovering}
    >
      <VideoBackground
        autoPlay
        loop
        muted={true}
        playsInline
        preload="auto"
        controls={false}
        src={require("../assets/video2.mp4")}
        hovering={hovering}
      />
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, background 0.3s ease;
  ${(props) =>
    props.hovering &&
    `
    transform: scale(1.02); 
  `}

  @media (max-width: 768px) {
    height: 50vh; /* Ajusta a altura para tablets */
  }

  @media (max-width: 480px) {
    height: 100vh; 
    width: 100%; 
  }
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  opacity: 0.9; /* Transparência inicial */
  transition: transform 0.5s ease, opacity 0.5s ease;

  ${(props) =>
    props.hovering &&
    `
    transform: scale(1.05); 
    opacity: 1; /* Torna mais visível ao passar o mouse */
  `}

  @media (max-width: 768px) {
    transform: none; 
    opacity: 1; /* Garante visibilidade total em tablets */
  }

  @media (max-width: 480px) {
    width: 100%;
    height: 100%; 
    transform: none; 
    object-fit: cover; 
    opacity: 1; /* Garante visibilidade total em dispositivos móveis */
  }
`;

export default InteractiveBanner;
