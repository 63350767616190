import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const GooniesInspiredSection = () => {
  const sectionRef = useRef(null);
  const overlayRef = useRef(null);
  const logoRef = useRef(null);
  const backgroundRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const sectionElement = sectionRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.9 } 
    );

    if (sectionElement) {
      observer.observe(sectionElement);
    }

    return () => {
      if (sectionElement) {
        observer.unobserve(sectionElement);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!isInView) return;

      const sectionElement = sectionRef.current;
      const overlay = overlayRef.current;
      const logo = logoRef.current;
      const background = backgroundRef.current;

      if (sectionElement) {
        const rect = sectionElement.getBoundingClientRect();
        const scrollProgress = Math.max(
          0,
          Math.min(1, 1 - rect.top / window.innerHeight)
        );

        if (background) {
          background.style.transform = `scale(${1 + scrollProgress * 0.3})`; 
          background.style.opacity = `${0.5 + scrollProgress * 0.5}`; 
        }

        if (overlay) {
          overlay.style.transform = `translateY(${scrollProgress * -30}px) scale(${1 + scrollProgress * 0.2})`; 
          overlay.style.opacity = `${1 - scrollProgress}`;
        }

        if (logo) {
          logo.style.transform = `translate(-50%, -50%) scale(${0.7 + scrollProgress * 0.6})`; 
          logo.style.opacity = `${Math.min(scrollProgress * 1.5, 1)}`; 
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isInView]);

  return (
    <Section ref={sectionRef}>
      <BackgroundImage
        ref={backgroundRef}
        src={require("../assets/BACKGROUND-SITE (3).gif")}
        alt="Background"
      />
      <OverlayImage
        ref={overlayRef}
        src={require("../assets/mato.png")}
        alt="Overlay"
      />
      <LogoImage ref={logoRef} src={require("../assets/LOGO.png")} alt="Logo" />
    </Section>
  );
};

const Section = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  opacity: 0.5; 
  transition: transform 0.9s ease, opacity 0.9s ease; 
`;

const OverlayImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
  transition: transform 0.8s ease, opacity 0.8s ease; 
`;

const LogoImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 180px;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: 3;
  opacity: 0;
  transition: transform 0.8s ease, opacity 0.8s ease; 
`;

export default GooniesInspiredSection;
