import React, { useState } from "react";
import styled from "styled-components";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    require("../assets/pods-carousel/pod1.png"),
    require("../assets/pods-carousel/pod2.png"),
    require("../assets/pods-carousel/pod3.png"),
    require("../assets/pods-carousel/pod4.png"),
    require("../assets/pods-carousel/pod5.png"),
    require("../assets/pods-carousel/pod6.png"),
    require("../assets/pods-carousel/pod7.png"),
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <SectionContainer>
      <Title>Discover some of our pods</Title>
      <CarouselWrapper>
        {images.map((image, index) => {
          const offset = (index - currentIndex + images.length) % images.length;
          let position = "";

          if (offset === 0) {
            position = "center";
          } else if (offset === 1 || offset === -images.length + 1) {
            position = "right";
          } else if (offset === -1 || offset === images.length - 1) {
            position = "left";
          } else {
            position = "hidden";
          }

          return (
            <CarouselItem key={index} $position={position}>
              <img src={image} alt={`Carousel item ${index + 1}`} />
            </CarouselItem>
          );
        })}
        <CarouselButton onClick={handlePrev}>‹</CarouselButton>
        <CarouselButton onClick={handleNext} $right>
          ›
        </CarouselButton>
      </CarouselWrapper>
    </SectionContainer>
  );
};

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 20px;

  @media (max-width: 768px) {
    height: 60vh;
  }

  @media (max-width: 480px) {
    height: 50vh;
  }
`;

const Title = styled.h2`
  font-size: 40px;
  color: #000;
  margin-bottom: 20px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 30px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const CarouselWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  height: 70%;
  overflow: hidden;
  border-radius: 15px;

  @media (max-width: 768px) {
    width: 90%;
    height: 60%;
  }

  @media (max-width: 480px) {
    height: 50%;
  }
`;

const CarouselItem = styled.div`
  position: absolute;
  transition: transform 0.5s ease, opacity 0.5s ease;
  transform: ${({ $position }) => {
    switch ($position) {
      case "center":
        return "scale(1) translateX(0)";
      case "left":
        return "scale(0.8) translateX(-120%)";
      case "right":
        return "scale(0.8) translateX(120%)";
      default:
        return "scale(0) translateX(0)";
    }
  }};
  opacity: ${({ $position }) => ($position === "hidden" ? 0 : 1)};
  pointer-events: ${({ $position }) => ($position === "center" ? "auto" : "none")};

  img {
    width: 300px;
    height: 400px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: ${({ $position }) =>
      $position === "center" ? "0 4px 15px rgba(0, 0, 0, 0.3)" : "none"};

    @media (max-width: 768px) {
      width: 200px;
      height: 300px;
    }

    @media (max-width: 480px) {
      width: 150px;
      height: 200px;
    }
  }
`;

const CarouselButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  ${({ $right }) => ($right ? "right: 10px;" : "left: 10px;")};

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
    font-size: 14px;
    ${({ $right }) => ($right ? "right: 5px;" : "left: 5px;")};
  }
`;

export default Carousel;
